/* Grabbed from https://bitbucket.org/polestardevhouse/web3-common-api/src/master/src/data/markets.ts */
export const ApiRegion = {
  Global: 'global',
  China: 'china'
};

export const MarketRegion = {
  NorthAmerica: 'North America',
  AsiaPacific: 'Asia-Pacific',
  Europe: 'Europe',
  MiddleEast: 'Middle-East',
  Global: 'Global'
};

export const MarketType = {
  Importer: 'Importer',
  PSU: 'PSU',
  SPSU: 'S-PSU'
};

export const markets = [
  // Europe
  {
    locale: 'fr-be',
    countryCode: 'be',
    cmsLocale: 'fr_BE',
    marketName: 'Belgique | Français',
    marketType: MarketType.PSU,
    languageCode: 'fr',
    languageName: 'Français',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'nl-be',
    marketName: 'België | Nederlands',
    marketType: MarketType.PSU,
    countryCode: 'be',
    cmsLocale: 'nl_BE',
    languageCode: 'nl',
    languageName: 'Vlaams',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'dk',
    countryCode: 'dk',
    cmsLocale: 'da_DK',
    marketName: 'Danmark',
    marketType: MarketType.PSU,
    languageCode: 'da',
    languageName: 'Dansk',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'de',
    marketName: 'Deutschland',
    marketType: MarketType.PSU,
    countryCode: 'de',
    cmsLocale: 'de_DE',
    languageCode: 'de',
    languageName: 'Deutsch',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'es',
    marketName: 'España',
    marketType: MarketType.PSU,
    countryCode: 'es',
    cmsLocale: 'es_ES',
    languageCode: 'es',
    languageName: 'Castilian',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'en-is',
    marketName: 'Iceland',
    marketType: MarketType.Importer,
    countryCode: 'is',
    cmsLocale: 'en_IS',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: []
  },
  {
    locale: 'ie',
    marketName: 'Ireland',
    marketType: MarketType.SPSU,
    countryCode: 'ie',
    cmsLocale: 'en_IE',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'fr-lu',
    marketName: 'Luxembourg',
    marketType: MarketType.SPSU,
    countryCode: 'lu',
    cmsLocale: 'fr_LU',
    languageCode: 'fr',
    languageName: 'Français',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  // {
  //   locale: 'de-lu',
  //   marketName: 'Luxembourg',
  //marketType: MarketType.SPSU,
  //   countryCode: 'lu',
  //   cmsLocale: 'de_LU',
  //   languageCode: 'de',
  //   languageName: 'Deutsch',
  //   region: MarketRegion.Europe,
  //   apiRegion: ApiRegion.Global,
  //   dateFormat: 'DD.MM.YYYY',
  //   features: []
  // },
  {
    locale: 'nl',
    marketName: 'Nederland',
    marketType: MarketType.PSU,
    countryCode: 'nl',
    cmsLocale: 'nl_NL',
    languageCode: 'nl',
    languageName: 'Nederlands',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'no',
    countryCode: 'no',
    cmsLocale: 'nb_NO',
    marketName: 'Norge',
    marketType: MarketType.PSU,
    languageCode: 'nb',
    languageName: 'Norsk',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'YYYY.MM.DD',
    features: ['enableLogin']
  },
  {
    locale: 'de-ch',
    countryCode: 'ch',
    cmsLocale: 'de_CH',
    marketName: 'Schweiz | Deutsch',
    marketType: MarketType.PSU,
    languageCode: 'de',
    languageName: 'Deutsch',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'fr-ch',
    countryCode: 'ch',
    cmsLocale: 'fr_CH',
    marketName: 'Suisse | Français',
    marketType: MarketType.PSU,
    languageCode: 'fr',
    languageName: 'Français',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'it',
    countryCode: 'it',
    cmsLocale: 'it_IT',
    marketName: 'Italia',
    marketType: MarketType.PSU,
    languageCode: 'it',
    languageName: 'Italiano',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'it-ch',
    countryCode: 'ch',
    cmsLocale: 'it_CH',
    marketName: 'Svizzera | Italiano',
    marketType: MarketType.PSU,
    languageCode: 'it',
    languageName: 'Italiano',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'fi',
    countryCode: 'fi',
    cmsLocale: 'fi_FI',
    marketName: 'Suomi',
    marketType: MarketType.PSU,
    languageCode: 'fi',
    languageName: 'Suomalainen',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'se',
    marketName: 'Sverige',
    marketType: MarketType.PSU,
    countryCode: 'se',
    cmsLocale: 'sv_SE',
    languageCode: 'sv',
    languageName: 'Svenska',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'YYYY.MM.DD',
    features: ['enableLogin']
  },
  {
    locale: 'pt',
    marketName: 'Portugal',
    marketType: MarketType.SPSU,
    countryCode: 'pt',
    cmsLocale: 'pt_PT',
    languageCode: 'pt',
    languageName: 'Portuguese',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'uk',
    marketName: 'United Kingdom',
    marketType: MarketType.PSU,
    countryCode: 'gb',
    cmsLocale: 'en_GB',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'at',
    countryCode: 'at',
    cmsLocale: 'de_AT',
    marketName: 'Österreich',
    marketType: MarketType.PSU,
    languageCode: 'de',
    languageName: 'Deutsch',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'fr',
    countryCode: 'fr',
    cmsLocale: 'fr_FR',
    marketName: 'France',
    marketType: MarketType.PSU,
    languageCode: 'fr',
    languageName: 'Français',
    region: MarketRegion.Europe,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },

  // North America
  {
    locale: 'en-ca',
    marketName: 'Canada | English',
    marketType: MarketType.PSU,
    countryCode: 'ca',
    cmsLocale: 'en_CA',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.NorthAmerica,
    apiRegion: ApiRegion.Global,
    dateFormat: 'MM.DD.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'fr-ca',
    marketName: 'Canada | Français',
    marketType: MarketType.PSU,
    countryCode: 'ca',
    cmsLocale: 'fr_CA',
    languageCode: 'fr',
    languageName: 'Français',
    region: MarketRegion.NorthAmerica,
    apiRegion: ApiRegion.Global,
    dateFormat: 'YYYY.MM.DD',
    features: ['enableLogin']
  },
  {
    locale: 'us',
    marketName: 'United States',
    marketType: MarketType.PSU,
    countryCode: 'us',
    cmsLocale: 'en_US',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.NorthAmerica,
    apiRegion: ApiRegion.Global,
    dateFormat: 'MM.DD.YYYY',
    features: ['enableLogin']
  },

  // Asia-Pacific
  {
    locale: 'au',
    marketName: 'Australia',
    marketType: MarketType.PSU,
    countryCode: 'au',
    cmsLocale: 'en_AU',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.AsiaPacific,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: ['enableLogin']
  },
  {
    locale: 'hk',
    marketName: 'Hong Kong - China',
    marketType: MarketType.Importer,
    countryCode: 'hk',
    cmsLocale: 'en_HK',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.AsiaPacific,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: []
  },
  {
    locale: 'kr',
    marketName: '대한민국',
    marketType: MarketType.PSU,
    countryCode: 'kr',
    cmsLocale: 'ko_KR',
    languageCode: 'ko',
    languageName: '한국어',
    region: MarketRegion.AsiaPacific,
    apiRegion: ApiRegion.Global,
    dateFormat: 'YYYY.MM.DD',
    features: ['enableLogin']
  },
  {
    locale: 'nz',
    marketName: 'New Zealand',
    marketType: MarketType.Importer,
    countryCode: 'nz',
    cmsLocale: 'en_NZ',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.AsiaPacific,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: []
  },
  {
    locale: 'en-sg',
    marketName: 'Singapore',
    marketType: MarketType.Importer,
    countryCode: 'sg',
    cmsLocale: 'en_SG',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.AsiaPacific,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: []
  },
  {
    locale: 'zh-cn',
    marketName: '中国',
    marketType: MarketType.PSU,
    countryCode: 'cn',
    cmsLocale: 'zh_CN',
    languageCode: 'zh',
    languageName: '中文',
    region: MarketRegion.AsiaPacific,
    apiRegion: ApiRegion.China,
    dateFormat: 'YYYY.MM.DD',
    features: []
  },

  // MiddleEast
  {
    locale: 'en-il',
    marketName: 'Israel',
    marketType: MarketType.Importer,
    countryCode: 'il',
    cmsLocale: 'en_IL',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.MiddleEast,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: []
  },
  {
    locale: 'en-kw',
    marketName: 'Kuwait',
    marketType: MarketType.Importer,
    countryCode: 'kw',
    cmsLocale: 'en_KW',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.MiddleEast,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: []
  },
  {
    locale: 'en-ae',
    marketName: 'United Arab Emirates',
    marketType: MarketType.Importer,
    countryCode: 'ae',
    cmsLocale: 'en_AE',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.MiddleEast,
    apiRegion: ApiRegion.Global,
    dateFormat: 'DD.MM.YYYY',
    features: []
  },

  // Global
  {
    locale: 'global',
    marketName: 'Global',
    marketType: MarketType.Importer,
    countryCode: 'global',
    cmsLocale: 'glb',
    languageCode: 'en',
    languageName: 'English',
    region: MarketRegion.Global,
    apiRegion: ApiRegion.Global,
    dateFormat: 'YYYY.MM.DD',
    features: []
  }
];

import { useContext, useState } from 'react';

import * as R from 'ramda';

import { MarketsContext, DatoBaseContext } from '~app/providers';

const DATO_MARKET_NAMES_KEYS = {
  au: 'marketNamesAustralia',
  at: 'marketNamesAustria',
  be: 'marketNamesBelgium',
  ca: 'marketNamesCanada',
  dk: 'marketNamesDenmark',
  fi: 'marketNamesFinland',
  de: 'marketNamesGermany',
  kr: 'marketNamesKorea',
  lu: 'marketNamesLuxembourg',
  nl: 'marketNamesNetherlands',
  no: 'marketNamesNorway',
  se: 'marketNamesSweden',
  ch: 'marketNamesSwitzerland',
  gb: 'marketNamesUnitedKingdom',
  us: 'marketNamesUnitedStates',
  ie: 'marketNamesIreland',
  es: 'marketNamesSpain',
  pt: 'marketNamesPortugal',
  cn: 'marketNamesChina',
  it: 'marketNamesItaly',
  fr: 'marketNamesFrance'
};

const DATO_LANGUAGE_NAMES_KEYS = {
  swedish: 'languagesSwedish',
  norwegian: 'languagesNorwegian',
  german: 'languagesGerman',
  dutch: 'languagesDutch',
  french: 'languagesFrench',
  danish: 'languagesDanish',
  finnish: 'languagesFinnish',
  english: 'languagesEnglish',
  italian: 'languagesItalian',
  korean: 'languagesKorean',
  chinese: 'languagesChinese',
  spanish: 'languagesSpanish',
  portuguese: 'languagesPortuguese'
};

const language = R.curry((markets, languageId) => {
  const marketId = R.compose(R.toLower, R.last, R.split('_'))(languageId);

  return R.find(R.propEq(languageId, 'cmsLocale'))(markets?.[marketId]?.language ?? []);
});

const toLanguageOptions = R.curry((markets, marketId) =>
  R.map(
    ({ cmsLocale: value, languageName: label }) => ({ value, label }),
    markets?.[marketId]?.language ?? []
  )
);

/**
 * toMarketOptions
 *
 * Takes a markets object and returns an array of {label, value}
 * objects suited for a <select /> element. Sorted based on label value.
 *
 * @param {{[marketId]: {marketId, marketName}}} markets
 * @returns [{value, label}]
 */
const toMarketOptions = (markets) => () =>
  R.compose(
    R.sortBy(R.prop('label')),
    R.map(({ marketId: value, marketName: label }) => ({ value, label })),
    R.values
  )(markets ?? {});

const market = R.curry((markets, marketId) => markets?.[marketId]);
const marketName = R.curry((markets, marketId) => markets?.[marketId]?.marketName);

const useMarket = () => {
  const rawMarkets = useContext(MarketsContext);
  const datoBase = useContext(DatoBaseContext);

  const [markets] = useState(
    R.reduce(
      (markets, m) => ({
        ...markets,
        [m?.marketId]: {
          ...m,
          spacePortalMarketId: datoBase?.spacePortalMarkets?.find(
            (item) => item.marketId?.toLowerCase() === m?.marketId
          )?.id,
          marketName:
            datoBase?.marketAndLanguage?.[DATO_MARKET_NAMES_KEYS[m?.marketId]] ?? m?.marketName,
          language: R.map(
            ({ languageName, ...rest }) => ({
              ...rest,
              languageName:
                datoBase?.marketAndLanguage?.[DATO_LANGUAGE_NAMES_KEYS[R.toLower(languageName)]] ??
                languageName
            }),
            m?.language ?? []
          )
        }
      }),
      {},
      rawMarkets
    )
  );

  return {
    markets,
    toMarketOptions: toMarketOptions(markets),
    toLanguageOptions: toLanguageOptions(markets),
    language: language(markets),
    market: market(markets),
    marketName: marketName(markets)
  };
};

export default useMarket;

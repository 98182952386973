import {
  Column,
  Orientations,
  Paragraph,
  Spacer,
  Spacings
} from '@polestar/component-warehouse-react';
import React from 'react';
import { SubTitle, Title } from './style';

export const PageTitle = ({ title, subTitle, description, bottomSpacing = Spacings.xLarge }) => {
  return (
    <Column>
      <Title className="page-title" data-testid="page-title">
        {title}
      </Title>

      {subTitle && (
        <SubTitle className="sub-title" data-testid="page-sub-title">
          {subTitle}
        </SubTitle>
      )}

      {description && (
        <>
          <Spacer orientation={Orientations.vertical} spacing={Spacings.xLarge} />
          <Paragraph>{description}</Paragraph>
        </>
      )}

      <Spacer orientation={Orientations.vertical} spacing={bottomSpacing} />
    </Column>
  );
};

export default PageTitle;
